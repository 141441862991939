import React from 'react';
import './ContactMethods.scss';
import ContactMethodItem from './ContactMethodItem';

const ContactMethods = () => {
    return (
        <div className="row methods">
            <ContactMethodItem title="send us a message" icon="pencil-alt" paragraph="Fill in the form below and a member of our team will get back to you" />
            <ContactMethodItem title="give us a call" icon="phone" paragraph="Call us on" phone="0203 393 9579" />
            <ContactMethodItem title="Tweet us" icon="twitter" paragraph="Send us a message on X">
                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/CosmoEducates" style={{ display: 'inline-block' }}>
                    @CosmoEducates
                </a>
            </ContactMethodItem>
        </div>
    );
};

export default ContactMethods;