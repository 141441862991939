import React from 'react';
import Hero from '../../Assets/Images/Training/books.jpg';
import HeroMob from '../../Assets/Images/Training/books-mob.jpg';
import Header from '../Shared/Components/Header/Header';
import Programmes from './Components/Programmes/Programmes';
import TextRow from './../Shared/Components/TextRow/TextRow';
import { Helmet } from "react-helmet";
import ScrollingBanner from '../Shared/Components/ScrollingBanner/ScrollingBanner';
import { TestimonialArray } from './Components/TestimonialData';
import CourseTestimonials from './Components/CourseTestimonials/CourseTestimonials';

const BespokeTraining = () => {
    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>The Sustainability Academy | Sustainability Training</title>
                <meta name="description" content="The SDGs will only be successful if all people are engaged and educated on them" />
            </Helmet>

            <Header
                headerImage={Hero}
                headerImageMob={HeroMob}
                lineText="Solutions"
                title="Our Courses"
                subtitle="Helping your business to grow sustainably" />

            <TextRow
                title="SDGs for your organisation"
                lineText="We are here to help embed sustainability"
                divClass="bgGrey"
                paragraph="The SDGs provide the framework for the sustainability strategies of most organisations. Through CSR initiatives and annual reports, organisations can demonstrate a broad understanding of the SDGs but without engagement of the entire colleague population, no organisation can hope to embed SDGs into every day decision making which will limit the potential of the goals being successfully implemented by 2030. By working with The Sustainability Academy, your organisation can engage all colleagues on the SDGs, helping them to understand how they can play their part in a global movement."
            />

            <TextRow
                divClass="bgNavy"
                animate={true}
                lineText="Helping your colleagues to support your organisation to achieve your sustainability Goals"
                title="Sustainable Development Goals Diploma"
            >
                <div className="row">
                    <div className="text-center col-lg-10 offset-lg-1">
                        <p style={{ fontSize: "17px" }}>We have created a range of solutions to embed sustainability at the heart of your organisation. We use the United Nations Sustainable Development goals as a framework to create training bundles aligned to your organisational goals.</p>
                        <p style={{ fontSize: "17px" }}>Breaking down the elements of local, national and global sustainability strategies to help your teams to understand how they can positively impact your ESG and sustainability targets.</p>
                    </div>
                </div>
            </TextRow>

            <Programmes />

            <CourseTestimonials
                divClass="bgNavy courseTestimonials"
                lineText="Testimonials"
                title="Education with impact"
                paragraph="Here is a snapshot of what our candidates are saying"
                data={TestimonialArray}
            />

            <ScrollingBanner />

        </>
    );
};

export default BespokeTraining;