import React from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';
import Logo from '../../Assets/Images/TheSustanabilityAcademy-LandscapeWhite.png';
import LineIcon from 'react-lineicons';

const DATE = new Date();

const Footer = () => {
    return (
        <footer className="container-fluid">
            <div className="row footer py-1">
                <div className="container-xxl">
                    <div className="row mt-4 mb-2">
                        <div className="brand col-lg-3 col-md-12 col-sm-12 mb-3">
                            <div className="flogo">
                                <img src={Logo} alt="logo" width="" height="" className="my-2 img-fluid footer-logo" />
                            </div>
                        </div>
                        <div className="finfo-cont col-lg-3 col-md-4 my-3 col-sm-12 px-0" >
                            <div className="finfo">
                                <div className="mb-1">
                                    <LineIcon name="twitter" alt="The Sustainability Academy twitter" style={{ color: "white", marginRight: '15px', fontSize: '1rem', display: 'inline-block' }} />
                                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/CosmoEducates" style={{ display: 'inline-block' }}>
                                        @CosmoEducates
                                    </a>
                                </div>
                                <div className="mb-1">
                                    <LineIcon name="instagram" alt="The Sustainability Academy instagram" style={{ color: "white", marginRight: '15px', fontSize: '1rem', display: 'inline-block' }} />
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/thesustainabilityacademy" style={{ display: 'inline-block' }}>
                                        thesustainabilityacademy
                                    </a>
                                </div>
                                <div className="mb-1">
                                    <LineIcon name="linkedin-original" alt="The Sustainability Academy LinkedIn" style={{ color: "white", marginRight: '15px', fontSize: '1rem', display: 'inline-block' }} />
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/thesustainabilityacademy" style={{ display: 'inline-block' }}>
                                        thesustainabilityacademy
                                    </a>
                                </div>
                                <div className="mb-1">
                                    <LineIcon name="phone" alt="Call Us" style={{ color: "white", marginRight: '15px', fontSize: '1rem', display: 'inline-block' }} />
                                    <a href="tel:02033939579" style={{ display: 'inline-block' }}>
                                        0203 393 9579
                                    </a>
                                </div>
                                <div className="mb-1">
                                    <LineIcon name="envelope" alt="Email Us" style={{ color: "white", marginRight: '15px', fontSize: '1rem', display: 'inline-block' }} />
                                    <a href="mailto:info@thesustainabilityacademy.co.uk" style={{ display: 'inline-block' }}>
                                        info@thesustainabilityacademy.co.uk
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="finfo-cont col-lg-3 col-md-4 my-3 col-sm-12 px-0" >
                            <div className="finfo">
                                <h2>About</h2>
                                <Link className="footer-link" to="/contact-us">Contact Us</Link>
                                <Link className="footer-link" to="/terms-and-conditions">Terms & Conditions</Link>
                                <Link className="footer-link" to="/privacy-policy">Privacy Policy</Link>
                            </div>
                        </div>
                        <div className="finfo-cont col-lg-3 col-md-4 my-3 col-sm-12 px-0" >
                            <div className="finfo">
                                <h2>Registered office</h2>
                                <p>The Sustainability Academy</p>
                                <p>Brunswick House</p>
                                <p>Brunswick Way</p>
                                <p>Liverpool</p>
                                <p>L34BN</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row footer-bottom">
                <div className="col-12 pt-3 text-center">
                    <p>&copy; The Sustainability Academy {DATE.getFullYear()}</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;