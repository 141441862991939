import React from 'react';
import PartnersRow from '../../../OurSupporters/Components/PartnersRow';
import RAF from '../../../../Assets/Images/OurPartners/RAFAC.jpg';
import THAMES from '../../../../Assets/Images/OurPartners/Thames.png';
import PEEL from '../../../../Assets/Images/OurPartners/Peel Logo.png';
import ALCESTER from '../../../../Assets/Images/OurPartners/Alcester-Academy-Logo.png';
const QuotesSection = () => {
    return (
        <>
            <PartnersRow
                lineText="RAF Cadets"
                title="RAF Cadets"
                paragraph="It's been an honour to collaborate with The Sustainability Academy in designing RAFAC's bespoke Level 2 qualification in Sustainability. This groundbreaking programme represents a significant step towards preparing our cadets for future roles, both within the RAF and beyond. We're not just teaching sustainability; we're inspiring a generation of advocates."
                image={RAF}
                divClass="no-border-img"
                rowReverse={true}
                animate={true}
                client="Wing Commander Dr Jill Matterface - Head of transformational training Royal Air Force Cadets"
            />

            <PartnersRow
                lineText="Thames Water"
                title="Thames Water"
                paragraph="This is a great initiative, which has helped our colleagues understand the importance of the Sustainable Development Goals and how the water industry can help achieve them. We want to make a positive impact on society and play our part in educating and inspiring future generations and we look forward to exploring how we expand this training in the future."
                image={THAMES}
                divClass="no-border-img"
                rowReverse={false}
                animate={true}
                client="Karima Khandker, Head of talent and skills, Thames Water"
            />
            <PartnersRow
                lineText="Peel Land and Property"
                title="Peel Land and Property"
                paragraph="Acting more sustainably is not only the right thing to do but key to our future commercial success. Unlocking the potential of our people to deliver on our sustainability agenda means empowering them so that they can play their part. By working with organisations like The Sustainability Academy, we are ensuring all colleagues across our organisation are equipped with the knowledge to implement our ambitious plans. It also ensures that as an organisation Peel L&P can demonstrate its commitment to the successful implementation of the Sustainable Development Goals by 2030."
                image={PEEL}
                divClass="no-border-img"
                rowReverse={true}
                animate={true}
                client="Jo Holden, Sustainability & ESG Director, Peel Land & Property"
            />

            <PartnersRow
                lineText="Alcester Academy"
                title="Alcester Academy"
                paragraph="It is essential that this amazing programme is recognised as it has had such a positive impact at Alcester Academy. We now have a conservation area, and I will be interviewing for Pupil Sustainability Ambassadors during this half term. Sustainability is now part of our Personal Development programme and scheduled into this academic year assembly schedule. You have totally inspired me, and your fantastic work will continue through our curriculum at the Academy."
                image={ALCESTER}
                divClass="no-border-img"
                rowReverse={false}
                animate={true}
                client="Miss Charlie McKenzie, Year 7 teacher Alcester Academy"
            />
        </>
    );
};

export default QuotesSection;