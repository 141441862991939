import React, { useEffect, useState } from 'react';
import './GoalList.scss';
import Intro from '../../../../Assets/Images/Training/Courses/sdg-intro.jpg';
import Goal1 from '../../../../Assets/Images/Training/Courses/img-goal-1.jpg';
import Goal2 from '../../../../Assets/Images/Training/Courses/img-goal-2.jpg';
import Goal3 from '../../../../Assets/Images/Training/Courses/img-goal-3.jpg';
import Goal4 from '../../../../Assets/Images/Training/Courses/img-goal-4.jpg';
import Goal5 from '../../../../Assets/Images/Training/Courses/img-goal-5.jpg';
import Goal6 from '../../../../Assets/Images/Training/Courses/img-goal-6.jpg';
import Goal7 from '../../../../Assets/Images/Training/Courses/img-goal-7.jpg';
import Goal8 from '../../../../Assets/Images/Training/Courses/img-goal-8.jpg';
import Goal9 from '../../../../Assets/Images/Training/Courses/img-goal-9.jpg';
import Goal10 from '../../../../Assets/Images/Training/Courses/img-goal-10.jpg';
import Goal11 from '../../../../Assets/Images/Training/Courses/img-goal-11.jpg';
import Goal12 from '../../../../Assets/Images/Training/Courses/img-goal-12.jpg';
import Goal13 from '../../../../Assets/Images/Training/Courses/img-goal-13.jpg';
import Goal14 from '../../../../Assets/Images/Training/Courses/img-goal-14.jpg';
import Goal15 from '../../../../Assets/Images/Training/Courses/img-goal-15.jpg';
import Goal16 from '../../../../Assets/Images/Training/Courses/img-goal-16.jpg';
import Goal17 from '../../../../Assets/Images/Training/Courses/img-goal-17.jpg';
import UN01 from '../../../../Assets/Images/Training/UNLogos/UN-01.jpg';
import UN02 from '../../../../Assets/Images/Training/UNLogos/UN-02.jpg';
import UN03 from '../../../../Assets/Images/Training/UNLogos/UN-03.jpg';
import UN04 from '../../../../Assets/Images/Training/UNLogos/UN-04.jpg';
import UN05 from '../../../../Assets/Images/Training/UNLogos/UN-05.jpg';
import UN06 from '../../../../Assets/Images/Training/UNLogos/UN-06.jpg';
import UN07 from '../../../../Assets/Images/Training/UNLogos/UN-07.jpg';
import UN08 from '../../../../Assets/Images/Training/UNLogos/UN-08.jpg';
import UN09 from '../../../../Assets/Images/Training/UNLogos/UN-09.jpg';
import UN10 from '../../../../Assets/Images/Training/UNLogos/UN-10.jpg';
import UN11 from '../../../../Assets/Images/Training/UNLogos/UN-11.jpg';
import UN12 from '../../../../Assets/Images/Training/UNLogos/UN-12.jpg';
import UN13 from '../../../../Assets/Images/Training/UNLogos/UN-13.jpg';
import UN14 from '../../../../Assets/Images/Training/UNLogos/UN-14.jpg';
import UN15 from '../../../../Assets/Images/Training/UNLogos/UN-15.jpg';
import UN16 from '../../../../Assets/Images/Training/UNLogos/UN-16.jpg';
import UN17 from '../../../../Assets/Images/Training/UNLogos/UN-17.jpg';
import Courses from '../Courses/Courses';
import Button from '../../../Shared/Components/Button/Button';
import CourseStats from '../CourseStats/CourseStats';

const goals = [
    {
        Title: "An introduction to Sustainable Development Goals",
        Description: "This course will provide an overview and insight for individuals to understand what the 17 United Nations Sustainable Development Goals are, who is involved, what the global commitment is and how this translates to organisations and individual responsibility.",
        CourseLength: "60 mins",
        Image: Intro,
        Category: "SDG"
    },
    {
        Title: "No Poverty",
        Description: "This programme will provide a full insight into the current global challenge to end poverty in all its forms everywhere and provide your colleague population an insight into how they can individually and collectively support your organisation and their community to achieve the goal and understand the challenge of goal 1.",
        CourseLength: "60 mins",
        Image: Goal1,
        Category: "SDG",
        Overlay: UN01
    },
    {
        Title: "Zero Hunger",
        Description: "This programme will provide a full overview into the global challenge to end hunger, achieve food security and improved nutrition. Promote sustainable agriculture and provide your colleague population with an insight into how they can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 2.",
        CourseLength: "60 mins",
        Image: Goal2,
        Category: "SDG",
        Overlay: UN02
    },
    {
        Title: "Good Health & Well-Being",
        Description: "This programme will provide a full overview into the current global challenge to ensure healthy lives and promote well-being for all at all ages. It will also provide insight into how your colleague population can individually and collectively support your organisation and  their community to achieve this goal and understand the challenge of goal 3.",
        CourseLength: "60 mins",
        Image: Goal3,
        Category: "SDG",
        Overlay: UN03
    },
    {
        Title: "Quality Education",
        Description: "This programme will provide a full overview of the current global challenge to ensure inclusive and equitable quality education, promote lifelong learning opportunities for all and provide insight into how your colleague population can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 4",
        CourseLength: "60 mins",
        Image: Goal4,
        Category: "SDG",
        Overlay: UN04
    },
    {
        Title: "Gender Equality",
        Description: "This programme will provide a full overview into the global challenge to achieve gender equality and empower all women and girls and provide your colleague population with an insight into how they can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 5",
        CourseLength: "60 mins",
        Image: Goal5,
        Category: "SDG",
        Overlay: UN05
    },
    {
        Title: "Clean Water & Sanitation",
        Description: "This programme will provide a full overview into the global challenge to ensure availability and sustainable management of water and sanitisation for all and provide your colleague population with an insight into how they can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 6",
        CourseLength: "60 mins",
        Image: Goal6,
        Category: "SDG",
        Overlay: UN06
    },
    {
        Title: "Affordable & Clean Energy",
        Description: "This programme will provide a full overview into the global challenge to ensure access to affordable, reliable, sustainable and modern energy for all and provide your colleague population with an insight into how they can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 7",
        CourseLength: "60 mins",
        Image: Goal7,
        Category: "SDG",
        Overlay: UN07
    },
    {
        Title: "Decent Work & Economic Growth",
        Description: "This programme will provide a full overview into the global challenge to promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all. It will also provide your colleague population with an insight into how they can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 8",
        CourseLength: "60 mins",
        Image: Goal8,
        Category: "SDG",
        Overlay: UN08
    },
    {
        Title: "Industry, Innovation & Infrastructure",
        Description: "This programme will provide a full overview into the global challenge to build resilient infrastructure, promote inclusive and sustainable industrialisation and foster innovation and provide your colleague population with an insight into how they can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 9",
        CourseLength: "60 mins",
        Image: Goal9,
        Category: "SDG",
        Overlay: UN09
    },
    {
        Title: "Reduced Inequalities",
        Description: "This programme will provide a full overview into the global challenge to reduce inequality within and among countries and provide your colleague population with an insight into how they can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 10",
        CourseLength: "60 mins",
        Image: Goal10,
        Category: "SDG",
        Overlay: UN10
    },
    {
        Title: "Sustainable Cities & Communities",
        Description: "This programme will provide a full overview into the global challenge to make cities and human settlements inclusive, safe, resilient and sustainable and provide your colleague population with an insight into how they can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 11",
        CourseLength: "60 mins",
        Image: Goal11,
        Category: "SDG",
        Overlay: UN11
    },
    {
        Title: "Responsible Consumption & Production",
        Description: "This programme will provide a full overview into the global challenge to ensure sustainable consumption and production patterns and provide your colleague population with an insight into how they can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 12",
        CourseLength: "60 mins",
        Image: Goal12,
        Category: "SDG",
        Overlay: UN12
    },
    {
        Title: "Climate Action",
        Description: "This programme will provide a full overview into the global challenge to take urgent action to combat climate change and its impacts and provide your colleague population with an insight into how they can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 13",
        CourseLength: "60 mins",
        Image: Goal13,
        Category: "SDG",
        Overlay: UN13
    },
    {
        Title: "Life Below Water",
        Description: "This programme will provide a full overview into the global challenge to conserve and sustainably use the oceans, seas and marine resources for sustainable development and provide your colleague population with an insight into how they can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 14",
        CourseLength: "60 mins",
        Image: Goal14,
        Category: "SDG",
        Overlay: UN14
    },
    {
        Title: "Life on Land",
        Description: "This programme will provide a full overview into the global challenge to protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, halt and reverse land degradation, halt biodiversity loss. It will also provide your colleague population with an insight into how they can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 15",
        CourseLength: "60 mins",
        Image: Goal15,
        Category: "SDG",
        Overlay: UN15
    },
    {
        Title: "Peace, Justice & Strong Institutions",
        Description: "This programme will provide a full overview into the global challenge to promote peaceful and inclusive societies for sustainable development. Provide access to justice for all and build effective, accountable and inclusive institutions at all levels and provide your colleague population with an insight into how they can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 16",
        CourseLength: "60 mins",
        Image: Goal16,
        Category: "SDG",
        Overlay: UN16
    },
    {
        Title: "Partnerships for The Goals",
        Description: "This programme will provide a full overview into the global challenge to strengthen the means of implementation and revitalise the global partnership for sustainable development  and provide your colleague population with an insight into how they can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 17",
        CourseLength: "60 mins",
        Image: Goal17,
        Category: "SDG",
        Overlay: UN17
    }
];
let goalsArray = [];

const GoalList = ({ divClass }) => {
    const [goalsToShow, setGoalsToShow] = useState([]);
    const [goalsPerPage] = useState(5);
    const [next, setNext] = useState(5);
    const [numberOfProgrammes, setNumberOfProgrammes] = useState();

    const loopGoalsWithSlice = (start, end) => {
        const slicedGoals = goals.slice(start, end);
        goalsArray = [...goalsArray, ...slicedGoals];

        setNumberOfProgrammes(goals.length);
        setGoalsToShow(goalsArray);
    };

    useEffect(() => {
        goalsArray = [];
        setGoalsToShow([])
        loopGoalsWithSlice(0, goalsPerPage);
    }, []);

    const handleLoadMoreGoals = () => {
        loopGoalsWithSlice(next, next + goalsPerPage);
        setNext(next + goalsPerPage);
    };

    return (
        <>
            <section className={`row no-gutters py-5 animated fadeInDelay h-100 ${divClass ? divClass : ""}`}>
                <div className="container-xxl">
                    <div className="container-ac">
                        <div className="col-12 text-center text-md-left py-5">
                            <h2 className="mb-3">Breaking down the Global Goals</h2>
                            <p>Digital modules that can be aligned specifically to your ESG targets and used as part of mandatory onboarding or staff development, raising awareness of sustainability and making the Goals relatable.</p>
                            <p style={{ fontWeight: 700 }}>Each module is certificated and accredited with a diploma achievement certificate awarded upon completion.</p>
                        </div>
                        <CourseStats countShowing={goalsArray.length} count={numberOfProgrammes} />
                        <Courses coursesToRender={goalsToShow} />
                        <CourseStats countShowing={goalsArray.length} count={numberOfProgrammes} />
                        {goalsToShow < goals &&
                            <Button buttonText="Load More" center={true} click={handleLoadMoreGoals} />
                        }
                    </div>
                </div>
            </section>
        </>
    );
};

export default GoalList;