import React from 'react';
import TextColumns from '../../Shared/Components/TextColumns/TextColumns';
import RowText from './../../Shared/Components/RowText/RowText';

const AboutIntro = () => {
    return (
        <div className="py-5">
            <RowText
                divClass="mb-3 pt-5"
                lineText="why?"
                title="Our Ambition"
                animate={true}>
                <div className="col-10 offset-1">
                    <p style={{ fontStyle: "italic" }}> To educate more organisations, schoolchildren and the wider community about sustainability and how this can positively impact on all of our lives</p>
                </div>
            </RowText>
            {/* <TextColumns
                center={true}
                col1a="We believe that business should be a force for good in creating a more sustainable future for all inhabitants of the planet. This belief runs through the DNA of our organisation and we set high standards for ourselves to uphold to ensure that we operate in the same way we envisioned when we were founded. In addition to our own internal standards, we want to hold ourselves publicly accountable and promote a culture of transparency so that people know who we are when they work with us and what they can expect from us as an organisation."
                col2a="With this in mind, we have launched The Sustainability Academy with the clear intention of attaining B Corp status and from day 1, we are preparing the necessary requirements for our application so that we can be listed amongst other businesses that meet the highest standards of verified social and environmental performance, public transparency, and legal accountability to balance profit and purpose."
            >
                <a href="https://issuu.com/thesustainabilityacademy/docs/the_sustainability_academy" target="_blank" className="ac-btn-full mt-3 mx-auto" style={{ width: "275px" }} role="button">
                    <div className="">
                        View Our Brochure
                    </div>
                </a>
            </TextColumns> */}
        </div>
    );
};

export default AboutIntro;